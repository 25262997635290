import { createTheme } from '@mui/material/styles';
import { PaletteMode } from '@mui/material';
import DefaultPalette from './DefaultPalette';

const createCustomTheme = (mode: PaletteMode, skin: any) => {
  const palette = DefaultPalette(mode, skin);

  return createTheme({
    palette: palette,
    typography: {
      allVariants: {
        fontFamily: 'Poppins, sans-serif',
      },
    },
    // Add any other customizations here
  });
};

export default createCustomTheme;
